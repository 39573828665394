import * as React from "react";
import { useMenuState, Menu, MenuItem, MenuButton } from "reakit/Menu";
import InaccessibleButtonWithModal from "./InaccessibleButtonWithModal";

function MenuWithButtonWithModal() {
  const state = useMenuState();
  return (
    <>
      <MenuButton {...state}>Open menu</MenuButton>
      <Menu {...state}>
        <MenuItem {...state} className="menuitem" as="div">
          Item 1
        </MenuItem>
        <MenuItem {...state} className="menuitem" as="div">
          Item 2
        </MenuItem>
        <MenuItem
          {...state}
          className="menuitem"
          as={InaccessibleButtonWithModal}
        >
          Toggle modal
        </MenuItem>
      </Menu>
    </>
  );
}

export default MenuWithButtonWithModal;
