var hasOwn = Object.prototype.hasOwnProperty;

module.exports = function (xs, f, acc) {
  var hasAcc = arguments.length >= 3;
  if (hasAcc && xs.reduce) return xs.reduce(f, acc);
  if (xs.reduce) return xs.reduce(f);

  for (var i = 0; i < xs.length; i++) {
    if (!hasOwn.call(xs, i)) continue;

    if (!hasAcc) {
      acc = xs[i];
      hasAcc = true;
      continue;
    }

    acc = f(acc, xs[i], i);
  }

  return acc;
};