import * as React from "react";
import Modal from "./Modal";

function mergeEvent(htmlHandler, handler) {
  return (event) => {
    if (htmlHandler) {
      // Call event callbacks coming from props
      htmlHandler(event);
    }
    // Check if the passed callback has called event.preventDefault()
    if (!event.defaultPrevented) {
      // Perform the component's default action
      handler(event);
    }
  };
}

function InaccessibleButtonWithModal(props, ref) {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClick = mergeEvent(props.onClick, () => setIsOpen(!isOpen));
  return (
    <>
      <div className="button" {...props} ref={ref} onClick={onClick} />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        Dialog
      </Modal>
    </>
  );
}

export default React.forwardRef(InaccessibleButtonWithModal);
