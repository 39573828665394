import * as React from "react";
import {
  unstable_useComboboxState as useComboboxState,
  unstable_Combobox as Combobox,
  unstable_ComboboxPopover as ComboboxPopover,
  unstable_ComboboxOption as ComboboxOption,
} from "reakit/Combobox";
import InaccessibleButtonWithModal from "./InaccessibleButtonWithModal";

function getHighlightedText(text, highlight) {
  const parts = text.split(new RegExp(`(${highlight})`, "i"));
  return parts.map((part, i) => (
    <span
      key={i}
      style={
        part.toLowerCase() === highlight.toLowerCase()
          ? { fontWeight: "bold" }
          : {}
      }
    >
      {part}
    </span>
  ));
}

function ComboboxWithButtonWithModal() {
  const state = useComboboxState({
    values: ["Item 1", "Item 2", "Toggle modal"],
  });
  return (
    <>
      <Combobox {...state} />
      <ComboboxPopover {...state}>
        {state.matches.map((value) => (
          <ComboboxOption {...state} key={value} value={value}>
            {({ onClick, ...props }) => (
              <InaccessibleButtonWithModal {...props}>
                {getHighlightedText(value, state.inputValue)}
              </InaccessibleButtonWithModal>
            )}
          </ComboboxOption>
        ))}
      </ComboboxPopover>
    </>
  );
}

export default ComboboxWithButtonWithModal;
