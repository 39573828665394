
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import { Steps, themes } from "mdx-deck";
import { CodeSurfer } from "code-surfer"
import { nightOwl } from "@code-surfer/themes"
import { Button } from "reakit/Button";
import InaccessibleButtonWithModal from "./components/InaccessibleButtonWithModal";
import MenuWithButtonWithModal from "./components/MenuWithButtonWithModal";
import ComboboxWithButtonWithModal from "./components/ComboboxWithButtonWithModal";
import lego from "./images/lego.png";
import "./style.css";
export const theme = nightOwl;

const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function InaccessibleButtonWithModal() {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div onClick={() => setIsOpen(!isOpen)}>Toggle modal</div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default InaccessibleButtonWithModal;
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <InaccessibleButtonWithModal mdxType="InaccessibleButtonWithModal">Toggle modal</InaccessibleButtonWithModal>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`const props = useButton();

<InaccessibleButtonWithModal {...props} />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button>
  {(props) => <InaccessibleButtonWithModal {...props} />}
</Button>
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button as={InaccessibleButtonWithModal} />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <Button as={InaccessibleButtonWithModal} mdxType="Button">Toggle modal</Button>
    <hr></hr>
    <h1>{`Componentes como peças de lego`}</h1>
    <img src={lego} alt="Lego" />
    <hr></hr>
    <h1>{`Diego Haz`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/diegohaz"
        }}>{`@diegohaz`}</a></li>
      <li parentName="ul">{`Author of Reakit`}</li>
      <li parentName="ul">{`JavaScript Engineer @ Automattic`}</li>
    </ul>
    <hr></hr>
    <img src="https://automattic.files.wordpress.com/2020/02/2019-09-11-automattic-web-1.jpg" alt="Automattic crew" />
    <p><a parentName="p" {...{
        "href": "https://automattic.com/work-with-us"
      }}>{`https://automattic.com/work-with-us`}</a></p>
    <hr></hr>
    <p>{`Por que a Michelle recebeu R$ 89 mil do Queiroz?`}</p>
    <hr></hr>
    <h1>{`Open/Closed Component`}</h1>
    <p><a parentName="p" {...{
        "href": "https://bit.ly/open-closed-component"
      }}>{`https://bit.ly/open-closed-component`}</a></p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function InaccessibleButtonWithModal() {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        className="my-button"
        style={{ color: "white" }}
        onClick={() => setIsOpen(!isOpen)}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default InaccessibleButtonWithModal;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function InaccessibleButtonWithModal(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        {...props}
        className="my-button"
        style={{ color: "white" }}
        onClick={() => setIsOpen(!isOpen)}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default InaccessibleButtonWithModal;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function InaccessibleButtonWithModal(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        {...props}
        className={\`my-button \${props.className}\`}
        style={{ color: "white" }}
        onClick={() => setIsOpen(!isOpen)}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default InaccessibleButtonWithModal;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function InaccessibleButtonWithModal(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        {...props}
        className={\`my-button \${props.className}\`}
        style={{ color: "white", ...props.style }}
        onClick={() => setIsOpen(!isOpen)}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default InaccessibleButtonWithModal;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function InaccessibleButtonWithModal(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        {...props}
        className={\`my-button \${props.className}\`}
        style={{ color: "white", ...props.style }}
        onClick={mergeEvents(props.onClick, () => setIsOpen(!isOpen))}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default InaccessibleButtonWithModal;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function InaccessibleButtonWithModal(props, ref) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        {...props}
        ref={ref}
        className={\`my-button \${props.className}\`}
        style={{ color: "white", ...props.style }}
        onClick={mergeEvents(props.onClick, () => setIsOpen(!isOpen))}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default React.forwardRef(InaccessibleButtonWithModal);
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function InaccessibleButtonWithModal(props, ref) {
  const localRef = React.useRef();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        {...props}
        ref={mergeRefs(localRef, ref)}
        className={\`my-button \${props.className}\`}
        style={{ color: "white", ...props.style }}
        onClick={mergeEvents(props.onClick, () => setIsOpen(!isOpen))}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default React.forwardRef(InaccessibleButtonWithModal);
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import styled from "styled-components";

const InaccessibleButton = styled.div\`
  color: black;
\`;
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Component Hooks`}</h1>
    <p><a parentName="p" {...{
        "href": "http://bit.ly/component-hooks"
      }}>{`http://bit.ly/component-hooks`}</a></p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function Button(props) {
  return <button {...props} />;
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function useButton(props) {
  return <button {...props} />;
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function useButton(props) {
  return {
    ...props,
  };
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function useButton(props) {
  return {
    role: "button",
    tabIndex: 0,
    ...props,
    onKeyDown: mergeEvents(props.onKeyDown, triggerClickOn("Enter")),
    onKeyUp: mergeEvents(props.onKeyUp, triggerClickOn("Space")),
  };
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Usage`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`const props = useButton();
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`const props = useButton();
<div {...props} />
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`const props = useButton();
<div {...props} />
<button {...props} />
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`const props = useButton();
<div {...props} />
<button {...props} />
<InaccessibleButtonWithModal {...props} />
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`const props = useButton(useTooltipReference());
<div {...props} />
<button {...props} />
<InaccessibleButtonWithModal {...props} />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Pros`}</h1>
    <hr></hr>
    <h1>{`Element Tree`}</h1>
    <img src="https://pbs.twimg.com/media/Ei_h6x5XYAE32mn?format=png&name=medium" style={{
      width: "50vw"
    }} />
    <hr></hr>
    <h1>{`Element Tree`}</h1>
    <img src="https://pbs.twimg.com/media/Ei_iE2OWoAEOgEc?format=jpg&name=medium" style={{
      width: "50vw"
    }} />
    <hr></hr>
    <h1>{`Performance`}</h1>
    <hr></hr>
    <h1>{`Cons`}</h1>
    <hr></hr>
    <h1>{`Rules of Hooks`}</h1>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-rules.html"
      }}>{`https://reactjs.org/docs/hooks-rules.html`}</a></p>
    <hr></hr>
    <h1>{`React Context & Portal`}</h1>
    <hr></hr>
    <h1>{`Render Props`}</h1>
    <p><a parentName="p" {...{
        "href": "http://bit.ly/react-render-props"
      }}>{`http://bit.ly/react-render-props`}</a></p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function Button({ children, ...props }) {
  return <button {...props}>{children}</button>;
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function Button({ children, ...props }) {
  props = useButton(props);
  return <button {...props}>{children}</button>;
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function Button({ children, ...props }) {
  props = useButton(props);
  if (typeof children === "function") {
    return children(props);
  }
  return <button {...props}>{children}</button>;
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Usage`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button>
  {(props) => <InaccessibleButtonWithModal {...props} />}
</Button>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button>
  {(props) => (
    <TooltipReference {...props}>
      {(props) => <InaccessibleButtonWithModal {...props} />}
    </TooltipReference>
  )}
</Button>
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Pros`}</h1>
    <hr></hr>
    <h1>{`It's a Component!`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function Dialog({ children, ...props }) {
  props = useDialog(props);

  const element = typeof children === "function"
    ? children(props)
    : <div {...props}>{children}</div>;

  return element;
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function Dialog({ children, ...props }) {
  props = useDialog(props);

  const element = typeof children === "function"
    ? children(props)
    : <div {...props}>{children}</div>;

  return (
    <Portal>
      <DialogProvider>
        {element}
      </DialogProvider>
    </Portal>
  );
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Cons`}</h1>
    <hr></hr>
    <h1>{`Element Tree`}</h1>
    <hr></hr>
    <h1>{`State Colocation`}</h1>
    <p><a parentName="p" {...{
        "href": "http://kcd.im/state-colocation"
      }}>{`http://kcd.im/state-colocation`}</a></p>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function App() {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      <Child />
      <Child />
      <Child />
    </Wrapper>
  );
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function App() {
  return (
    <StatefulComponent>
      <Child />
      <Child />
      <Child />
    </StatefulComponent>
  );
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx",
          "metastring": "5,13:15",
          "5,13:15": true
        }}>{`function StatefulComponent({ children }) {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      {children}
    </Wrapper>
  );
}

function App() {
  return (
    <StatefulComponent>
      <Child />
      <Child />
      <Child />
    </StatefulComponent>
  );
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function StatefulComponent({ children }) {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      {children}
    </Wrapper>
  );
}

function App() {
  return (
    <StatefulComponent>
      <Child selected={value === "1"} />
      <Child selected={value === "2"} />
      <Child selected={value === "3"} />
    </StatefulComponent>
  );
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function StatefulComponent({ children }) {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      {children(value)}
    </Wrapper>
  );
}

function App() {
  return (
    <StatefulComponent>
      {(value) => (
        <React.Fragment>
          <Child selected={value === "1"} />
          <Child selected={value === "2"} />
          <Child selected={value === "3"} />
        </React.Fragment>
      )}
    </StatefulComponent>
  );
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function StatefulComponent({ children }) {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      <Child selected={value === "1"} />
      <Child selected={value === "2"} />
      <Child selected={value === "3"} />
    </Wrapper>
  );
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`React.memo`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function App() {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      <Child selected={value === "1"} />
      <Child selected={value === "2"} />
      <Child selected={value === "3"} />
    </Wrapper>
  );
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx",
          "metastring": "1,7[14:38],8[14:38],9[14:38]",
          "1,7[14:38],8[14:38],9[14:38]": true
        }}>{`Child = React.memo(Child);

function App() {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      <Child selected={value === "1"} />
      <Child selected={value === "2"} />
      <Child selected={value === "3"} />
    </Wrapper>
  );
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`Child = React.memo(Child);

function App() {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      <Child selected={value === "1"}>
        {(props) => <InaccessibleButtonWithModal {...props} />}
      </Child>
      <Child selected={value === "2"} />
      <Child selected={value === "3"} />
    </Wrapper>
  );
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Component Injection`}</h1>
    <h2>{`"as" prop`}</h2>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function Button({ children, ...props }) {
  props = useButton(props);
  if (typeof children === "function") {
    return children(props);
  }
  return <button {...props}>{children}</button>;
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx",
          "metastring": "1[19:32],3:5",
          "1[19:32],3:5": true
        }}>{`function Button({ as: Component, children, ...props }) {
  props = useButton(props);
  if (Component) {
    return <Component {...props}>{children}</Component>;
  }
  if (typeof children === "function") {
    return children(props);
  }
  return <button {...props}>{children}</button>;
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Usage`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button as={InaccessibleButtonWithModal} />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Pros`}</h1>
    <hr></hr>
    <h1>{`It's a Component!`}</h1>
    <hr></hr>
    <h1>{`React.memo`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx",
          "metastring": "7:9",
          "7:9": true
        }}>{`Child = React.memo(Child);

function App() {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      <Child selected={value === "1"}>
        {(props) => <InaccessibleButtonWithModal {...props} />}
      </Child>
    </Wrapper>
  );
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`Child = React.memo(Child);

function App() {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      <Child
        as={InaccessibleButtonWithModal}
        selected={value === "1"}
      />
    </Wrapper>
  );
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx",
          "metastring": "1,8:9",
          "1,8:9": true
        }}>{`Child = React.memo(Child);

function App() {
  const [value, setValue] = React.useState();
  return (
    <Wrapper value={value} onChange={setValue}>
      <Child
        as={InaccessibleButtonWithModal}
        selected={value === "1"}
      />
    </Wrapper>
  );
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Less Verbosity`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`// Render props
<Button>
  {(props) => <InaccessibleButtonWithModal {...props} />}
</Button>

// Component injection
<Button as={InaccessibleButtonWithModal} />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Component Injection is Great!`}</h1>
    <h2>{`🙌`}</h2>
    <hr></hr>
    <h1>{`Cons`}</h1>
    <h2>{`😞`}</h2>
    <hr></hr>
    <h1>{`More Verbosity`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button>
  {(props) => <Component buttonProps={props} />}
</Button>
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`❌ <Button
     as={(props) => <Component buttonProps={props} />}
   />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function IntermediateComponent(props, ref) {
  return <Component buttonProps={props} ref={ref} />;
}
 
IntermediateComponent = React.forwardRef(IntermediateComponent);
 
<Button as={IntermediateComponent} />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`TypeScript`}</h1>
    <p><a parentName="p" {...{
        "href": "http://bit.ly/typescript-as-prop"
      }}>{`http://bit.ly/typescript-as-prop`}</a></p>
    <hr></hr>
    <h1>{`Prop Name Collision`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function Button({ as: Component, ...rest }) {
  ...
  return <Component {...rest} />;
}

function InaccessibleButtonWithModal({ label, ...rest }) {
  ...
  return <div {...rest}>{label}</div>;
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx",
          "metastring": "1[40:45],3[25:32]",
          "1[40:45],3[25:32]": true
        }}>{`function InaccessibleButtonWithModal({ label, ...rest }) {
  ...
  return <div {...rest}>{label}</div>;
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button as={InaccessibleButtonWithModal} label="Toggle modal" />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div>Toggle modal</div>
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function Button({ as: Component, ...rest }) {
  return <Component {...rest} />;
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx",
          "metastring": "1[34:48],2[21:49]",
          "1[34:48],2[21:49]": true
        }}>{`function Button({ as: Component, label, ...rest }) {
  return <Component aria-label={label} {...rest} />;
}
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Button as={InaccessibleButtonWithModal} label="Toggle modal" />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div aria-label="Toggle modal" />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <h1>{`Conclusion`}</h1>
    <hr></hr>
    <h1>{`Bonus`}</h1>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function InaccessibleButtonWithModal() {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div onClick={() => setIsOpen(!isOpen)}>Toggle modal</div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default InaccessibleButtonWithModal;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function ButtonWithModal() {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <button onClick={() => setIsOpen(!isOpen)}>Toggle modal</button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default ButtonWithModal;
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`function ButtonWithModal(props, ref) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <React.Fragment>
      <button
        {...props}
        ref={ref}
        onClick={mergeEvents(props.onClick, () => setIsOpen(!isOpen))}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </React.Fragment>
  );
}

export default React.forwardRef(ButtonWithModal);
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import { MenuItem } from "reakit/Menu";

<MenuItem>
  {(props) => <ButtonWithModal {...props} />}
</MenuItem>
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <MenuWithButtonWithModal mdxType="MenuWithButtonWithModal" />
    <hr></hr>
    <CodeSurfer mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import { ComboboxOption } from "reakit/Combobox";

<ComboboxOption as={ButtonWithModal} />
`}</code></pre>
    </CodeSurfer>
    <hr></hr>
    <ComboboxWithButtonWithModal mdxType="ComboboxWithButtonWithModal" />
    <hr></hr>
    <h1>{`Obrigado!`}</h1>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;