import * as React from "react";
import { useDialogState, Dialog } from "reakit/Dialog";
import useLiveRef from "../utils/useLiveRef";

function Modal({ isOpen, onClose, ...props }, ref) {
  const state = useDialogState({ visible: isOpen });
  const onCloseRef = useLiveRef(onClose);
  if (isOpen !== undefined && state.visible !== isOpen) {
    state.setVisible(isOpen);
  }
  const enhancedState = {
    ...state,
    hide: React.useCallback((...args) => {
      state.hide(...args);
      if (onCloseRef.current) {
        onCloseRef.current()
      }
    }, [state.hide])
  }
  return <Dialog {...enhancedState} {...props} ref={ref} />;
}

export default React.forwardRef(Modal);
